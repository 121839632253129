import React from "react";
import { Redirect } from "react-router";
import Button from "@material-ui/core/Button";

import API from "./../Components/api";
import { cancelHttpRequests } from "./../Helpers/HelperFunctions";

class InstructorCourseNames extends React.Component {
  cancelToken = API.getCancelactionToken();
  source = this.cancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      allCourses: [],
      goToCourse: false,
      courseId: null,
      isLoading: true,
    };
  }

  componentWillUnmount() {
    cancelHttpRequests(this.source);
  }

  render() {
    if (this.state.goToCourse && this.state.courseId)
      return (
        <Redirect
          from="/admin"
          to={"/instructor/course-details/" + this.state.courseId}
          push
        />
      );

    if (!this.props.courses || this.props.courses.length === 0)
      return <span>No courses taught this semester.</span>;

    return (
      <React.Fragment>
        <span className="arrowCursor" style={{ marginRight: "5px" }}>
          My Courses:
        </span>
        {this.props.courses.map((c) => {
          return (
            <Button
              className="embers whiteText"
              size="small"
              key={c["courseInstanceId"]}
              style={{
                marginRight: "5px",
                marginBottom: "5px",
                cursor: "pointer",
              }}
              onClick={() => this._goToCourse(c.CourseInstanceId)}
            >
                {`${c.Code} ${c.Number}`}
                {c.CampusId && (
                    <span
                        style={{
                            fontSize: "0.75em",
                            verticalAlign: "middle",
                            lineHeight: "1",
                            marginLeft: "4px"
                        }}
                    >
                        {`(${c.CampusId})`}
                    </span>
                )}
            </Button>
          );
        })}
      </React.Fragment>
    );
  }

  _goToCourse = (courseInstanceId) => {
    this.setState({ goToCourse: true, courseId: courseInstanceId });
  };
}

export default InstructorCourseNames;
