import React from "react";
import axios from "axios";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import WaitSpinner from "./../../Components/WaitSpinner";
import API from "./../../Components/api";
import CourseMasterAttributes from "./CourseMasterAttributes";
import { cancelHttpRequests } from "./../../Helpers/HelperFunctions";
import Checkbox from "@material-ui/core/Checkbox";

class CourseMasterWrapper extends React.Component {
    CancelToken = API.getCancelactionToken();
    source = this.CancelToken.source();

    classes = (theme) => ({
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    });

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isLoadingData: false,
            allMasterCourses: [],
            allContentLevels: [],
            allGAs: [],
            courseExpanded: [],
        };
    }

    componentDidMount() {
        this._getDropdownData();
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedProgram !== prevProps.selectedProgram ||
            this.props.selectedAcademicYear !== prevProps.selectedAcademicYear
        ) {
            var that = this;

            this.setState(
                {
                    isLoadingData: true,
                },
                () => {
                    that._getCourseMasterData();
                    that._getDropdownData();
                }
            );
        }
    }

    render() {
        let errorControl = null;

        if (this.state.errorMessage)
            errorControl = <div>Error: Unable to retrieve Course Plans.</div>;

        if (this.state.isLoading) {
            return (
                <div className="center-wrapper full-height">
                    <WaitSpinner></WaitSpinner>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div style={{ marginTop: "7px" }}>
                    {errorControl || this._renderMasterCourses()}
                </div>
            </React.Fragment>
        );
    }

    _getDropdownData = () => {
        let contentLevelPromise = API.get(`ContentLevel`, {
            cancelToken: this.source.token,
        });

        let gaPromise = API.get(`GraduateAttribute/GetList(programId=${this.props.selectedProgram},academicYearId=${this.props.selectedAcademicYear})?$expand=GAIs`, {
            cancelToken: this.source.token,
        });

        let that = this;
        axios
            .all([
                contentLevelPromise,
                gaPromise,
            ])
            .then(that._processDropDownListData)
            .catch((err) => {
                // TODO: Something
                if (API.isCancel(err)) return;
            });
    }

    //Track expand state so we don't unnecessarily render thousands of checkboxes that are just hidden in the ExpansionPanel
    _onCourseExpand = (coursePlanId, expanded) => {
        let courseExpanded = this.state.courseExpanded;

        if (expanded) {
            if (!courseExpanded.includes(coursePlanId)) {
                courseExpanded.push(coursePlanId);
            }
        } else {
            if (!courseExpanded.includes(coursePlanId)) {
                const index = courseExpanded.indexOf(coursePlanId);
                if (index > -1) {
                    courseExpanded.splice(index, 1);
                }
            }
        }

        this.setState({
            courseExpanded: [...courseExpanded],
        });
    };

    _renderMasterCourses = () => {
        if (this.state.isLoadingData) {
            return (
                <div className="center-wrapper full-height">
                    <WaitSpinner></WaitSpinner>
                </div>
            );
        }

        let arr = [];

        if (
            !this.state.allMasterCourses ||
            this.state.allMasterCourses.length === 0
        )
            return (
                <div className="full-width center" style={{ marginTop: "20px" }}>
                    No Course Plans exist for the selected Academic Year, Program Year,
                    and Program Term.
                </div>
            );
        arr.push(
            <div className="d-flex justify-content-end mb-3" style={{ gap: '10px', paddingBottom: '5px' }}>
                <button
                    className="btn text-white me-2"
                    style={{ backgroundColor: '#062F4F' }}
                    onClick={() => {
                        API.get(`CoursePlan/SetIncludeForModellingForAll(programId=${this.props.selectedProgram},academicYearId=${this.props.selectedAcademicYear},includeForModelling=true)`,
                            {
                                cancelToken: this.source.token,
                            }
                        )
                            .then((res) => {
                                // Fetch all new data.
                                window.location.reload();
                            })
                            .catch((err) => {
                                if (API.isCancel(err)) return;

                                alert("Unable to Check all.");
                            });
                    }}>
                    Check all
                </button>

                <button
                    className="btn text-white me-2"
                    style={{ backgroundColor: '#062F4F' }}
                    onClick={() => {
                        API.get(`CoursePlan/SetIncludeForModellingForAll(programId=${this.props.selectedProgram},academicYearId=${this.props.selectedAcademicYear},includeForModelling=false)`,
                            {
                                cancelToken: this.source.token,
                            }
                        )
                            .then((res) => {
                                // Fetch all new data.
                                window.location.reload();
                            })
                            .catch((err) => {
                                if (API.isCancel(err)) return;

                                alert("Unable to Uncheck all.");
                            });
                    }}>
                    Uncheck all
                </button>
            </div>
        );
        for (let idx = 0; idx < this.state.allMasterCourses.length; idx++) {
            var theKey = "expPanel" + idx.toString();
            var expansionText = "";

            if (this.state.allMasterCourses[idx].RequiresSetup)
                expansionText = "Requires Setup";
            else if (
                !this.state.allMasterCourses[idx].IsAdminVerified &&
                this.state.allMasterCourses[idx].OutlineChangeSummary
            )
                expansionText = "Please Check";

            arr.push(
                <ExpansionPanel
                    key={theKey}
                    className="expansionPanelSpacing"
                    onChange={(obj, expanded) => {
                        this._onCourseExpand(idx, expanded);
                    }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="white" />}
                        className="ink"
                    >
                        <div className="row" style={{ width: "100%" }}>
                            <div className="col-6">
                                <Typography
                                    style={{ color: "white" }}
                                    className={this.classes.heading}
                                >
                                    {this.state.allMasterCourses[idx].CourseCode}{" "}
                                    {this.state.allMasterCourses[idx].CourseNumber} (
                                    {this.state.allMasterCourses[idx].CourseName}
                                    {this.state.allMasterCourses[idx].SourceCourseId && (
                                        <> - Course Id: {this.state.allMasterCourses[idx].SourceCourseId}</>
                                    )})
                                    {this.state.allMasterCourses[idx].IsCourseEquivalent && (
                                        <span style={{ fontSize: "10px", verticalAlign: "top" }}> *Equivalent course</span>
                                    )}
                                    {this.state.allMasterCourses[idx].Instructors && (
                                        <>
                                            <br />
                                            <span className="font12 poppinFont">
                                                Instructor: {this.state.allMasterCourses[idx].Instructors}
                                                {this.state.allMasterCourses[idx].Campus && (
                                                    <span> - Campus: {this.state.allMasterCourses[idx].Campus}</span>
                                                )}
                                            </span>
                                        </>
                                    )}
                                </Typography>
                            </div>
                            <div className="col-3 poppinFont font14 white"
                                style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    checked={this.state.allMasterCourses[idx].IncludeForModelling}
                                    style={{
                                        color: 'white',
                                        backgroundColor: 'transparent',
                                        padding: 0,
                                        margin: 0,
                                    }}

                                    onClick={(event) => {
                                        event.stopPropagation();

                                        const originalCourses = [...this.state.allMasterCourses];

                                        // Update the checkmark property
                                        const updatedCourses = [...this.state.allMasterCourses];
                                        updatedCourses[idx] = {
                                            ...updatedCourses[idx],
                                            IncludeForModelling: !updatedCourses[idx].IncludeForModelling,
                                        };

                                        API.get(`CoursePlan/SetIncludeForModelling(coursePlanId=${this.state.allMasterCourses[idx].Id},programId=${this.state.allMasterCourses[idx].ProgramId},includeForModelling=${updatedCourses[idx].IncludeForModelling})`,
                                            {
                                                cancelToken: this.source.token,
                                            }
                                        )
                                            .catch((err) => {
                                                if (API.isCancel(err)) return;

                                                alert("Unable to set include for modelling value.");

                                                this.setState({ allMasterCourses: originalCourses });
                                            });

                                        // Set the new state
                                        this.setState({ allMasterCourses: updatedCourses });
                                    }}
                                />
                                Include for modelling
                            </div>
                            <div
                                className="col-3 poppinFont font14 turboYellow semibold d-flex align-items-center justify-content-end"
                            >
                                <span>{expansionText}</span>
                            </div>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {this.state.courseExpanded.includes(idx)
                            ? this._renderCourseMasterDetail(this.state.allMasterCourses[idx])
                            : ""}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        }

        return arr;
    };

    _stateUpdate = (coursePlanId, requiresSetup, isAdminVerified) => {
        let masterCourses = [...this.state.allMasterCourses];
        let coursePlanToUpdate = masterCourses.find((x) => x.Id === coursePlanId);
        coursePlanToUpdate.RequiresSetup = requiresSetup;
        coursePlanToUpdate.IsAdminVerified = isAdminVerified;
        this.setState({ allMasterCourses: masterCourses });
    };

    _renderCourseMasterDetail = (courseMaster) => {
        return (
            <CourseMasterAttributes
                courseMasterDetail={courseMaster}
                allContentLevels={this.state.allContentLevels}
                allGAs={this.state.allGAs}
                onStateUpdate={this._stateUpdate}
                programId={this.props.selectedProgram}
            />
        );
    };

    /**
     * Get Course Master Data
     */
    _getCourseMasterData = () => {
        const academYrId = this.props.selectedAcademicYear;
        const that = this;

        if (isNaN(academYrId)) return;

        API.get(`CoursePlan/GetCoursePlans(programId=${this.props.selectedProgram},academicYearId=${academYrId})`,
            {
                cancelToken: this.source.token,
            }
        )
            .then((res) => {
                // clean the data and convert all 0000000000000 to a single 0
                var sss = "blah";

                res.data.value.forEach((course) => {
                    course.CourseLearningOutcomes.forEach((clo) => {
                        try {
                            var theSplit = clo.CourseLearningOutcomeNumber.split(".");
                            var rightSide = theSplit[1];

                            if (theSplit[1].match("^0+$")) {
                                rightSide = "0";
                                clo.CourseLearningOutcomeNumber = theSplit[0] + "." + rightSide;
                            } else {
                                // trim the 0's from the end of 1.9841200000000000000000000
                                let lastChar = rightSide[rightSide.length - 1];

                                while (lastChar === "0") {
                                    rightSide = rightSide.substring(0, rightSide.length - 1);
                                    lastChar = rightSide[rightSide.length - 1];
                                }

                                clo.CourseLearningOutcomeNumber = theSplit[0] + "." + rightSide;
                            }
                        } catch (err) {
                            // TODO: Something
                        }
                    });
                });

                that.setState({
                    allMasterCourses: res.data.value,
                    isLoading: false,
                    isLoadingData: false,
                    errorMessage: null,
                });
            })
            .catch((err) => {
                if (API.isCancel(err)) return;

                that.setState({
                    allMasterCourses: null,
                    isLoading: false,
                    isLoadingData: false,
                    errorMessage: "Error: Unable to retrieve Course Plans.",
                });
            });
    };

    _processDropDownListData = (res) => {
        let that = this;

        this.setState(
            {
                allContentLevels: res[0].data.value,
                allGAs: res[1].data.value,
            },
            () => {
                that._getCourseMasterData();
            }
        );
    };
}

export default CourseMasterWrapper;
