import React from "react";
import { Redirect } from "react-router";

import "./CourseSummary.css";
import WaitSpinner from "./../Components/WaitSpinner";
import API from "./../Components/api";
import GAIOccurances from "./../GAIOccurances";

/**
 * Admin Menu: List Courses for a given Term Id
 */
class CourseSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openCourseId: null,
            termCourses: null
        };

        this._getAcademicTermCourses = this._getAcademicTermCourses.bind(this);
        this._openCourse = this._openCourse.bind(this);
    }

    componentDidMount() {
        this._getAcademicTermCourses();
    }

    componentDidUpdate(prevProps) {
        // on change of academicTermId, get new data
        if (
            this.props.academicTermId &&
            this.props.academicTermId !== prevProps.academicTermId ||
            this.props.programId !== prevProps.programId
        ) {
            this._getAcademicTermCourses();
        }
    }

    _renderGAIOccurances = (course) => {
        let gaiRequestCount = course.DataCaptures.length;
        return (
            <div className="row">
                <div className="col-2" style={{ minHeight: "42px", paddingTop: "10px" }}>
                    <span className="font-weight-bold marginRight5 poppinFont">
                        {gaiRequestCount}
                    </span>
                    <span className={"font14 poppinFont margin-right-20"}>GAIs Requested</span>
                </div>
                <div className="col-10">
                    <GAIOccurances CLOGaiDataCaptures={course.DataCaptures} />
                </div>
            </div>
        )
    }

    render() {
        if (
            this.state.termCourses === undefined ||
            this.state.termCourses === null
        ) {
            return (
                <div className="center-wrapper full-height">
                    <WaitSpinner />
                </div>
            );
        }

        if (this.state.openCourseId) {
            let newUrl = "/instructor/course-details/" + this.state.openCourseId;
            return <Redirect from="/" to={newUrl} push></Redirect>;
        }

        if (this.state.termCourses.length === 0) {

            return (
                <React.Fragment>
                    <div style={{ marginTop: "10px" }}>
                        <span className="arrowCursor">
                            No Courses have been added for this term
                        </span>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.state.termCourses.map((course) => {
                    var displayValue = course.SourceCourseId ? `${course.CourseNumber} - ${course.CourseName} (Course Id: ${course.SourceCourseId})` :
                        `${course.CourseNumber} - ${course.CourseName}`;

                    return (
                        <div
                            key={course.CourseInstanceId}
                            className="courseWrapper"
                            onClick={() => {
                                this._openCourse(course.CourseInstanceId);
                            }}
                        >
                            <div className="ink py-1">
                                <div className="row full-width">
                                    <div className="col-11 text-left">
                                        <span className="headerContent font16 poppinFont semibold">
                                            {displayValue}
                                            {course.IsCourseEquivalent && (
                                                <span style={{ fontSize: "10px", verticalAlign: "top" }}> *Equivalent course</span>
                                            )}
                                        </span>
                                        <br />
                                        <div className="white" style={{ paddingLeft: '20px', marginLeft: '0' }}>
                                            Instructor:
                                            <span className="marginLeft5 font12 poppinFont">
                                                {course.InstructorName}
                                                {course.CampusId && (
                                                    <span> - Campus: {course.CampusId}</span>
                                                )}
                                            </span>
                                        </div>
                                    </div>


                                    <div className="col-1 text-right my-auto">
                                        <span className="white d-none">
                                            Some alert here.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ paddingLeft: "20px", minHeight: "42px" }}>
                                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5" style={{ minHeight: "42px", paddingTop: "10px" }}>
                                    <span className="font-weight-bold marginRight5 poppinFont">
                                        {course.TotalStudents}
                                    </span>
                                    <span className={"font14 poppinFont margin-right-20"}>Students</span>
                                    <span className="font-weight-bold marginRight5 poppinFont">
                                        {course.CompletedAssessments} of {course.TotalAssessments}
                                    </span>
                                    <span className={"font14 poppinFont margin-right-20"}>Assessments Complete</span>
                                    <span className="font-weight-bold marginRight5 poppinFont">
                                        {course.TotalGAIsAssigned}
                                    </span>
                                    <span className={"font14 poppinFont"}>GAIs Mapped</span>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                                    {this._renderGAIOccurances(course)}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }

    /**
     * Get all Courses for a specific Term from the API
     */
    _getAcademicTermCourses() {
        var that = this;
        if (!this.props.programId || !this.props.academicTermId) return;

        API.get(`Admin/GetAcademicTermCourses(academicTermId=${this.props.academicTermId},programId=${this.props.programId})`)
            .then((res) => {
                that.setState({
                    termCourses: res.data.value,
                });
            })
            .catch((err) => {
                that.setState({
                    termCourses: [],
                });
            });
    }

    /**
     * Navigate to a course instance
     * @param {int} courseId Course Instance Id to open
     */
    _openCourse(courseId) {
        this.setState({
            openCourseId: courseId,
        });
    }
}

CourseSummary.propTypes = {};

export default CourseSummary;
