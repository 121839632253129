import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import StudentGrid from "./StudentGrid";
import API from "./../../Components/api";
import GaiChart from "./../../Assessment/GAIChart";
import WaitSpinner from "./../../Components/WaitSpinner";
import CustomRadioButton from "./../../Components/CustomRadioButton";
import "./CourseInstanceDetails.css";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
});

class CourseInstanceDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            overallChartData: null,
            byGaiChartData: null,
            chartFilter: "overallChartData",
        };
    }

    componentDidMount() {
        this._getData();
    }

    _getChartDataByChanged = (event) => {
        this.setState({ chartFilter: event.target.value }, () => {
            this._getData();
        });
    };

    render() {
        let theChart = null;

        if (
            this.state[this.state.chartFilter] !== undefined &&
            this.state[this.state.chartFilter] !== null
        ) {
            var chartData = this.state[this.state.chartFilter].map((elem) => {
                return [elem.xValue, elem.percent, elem.columnLabel];
            });

            theChart = (
                <React.Fragment>
                    <GaiChart data={{ chartData }} />
                    <div className="course-chart-switch">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="filter"
                                name="filter"
                                value={this.state.chartFilter}
                                onChange={this._getChartDataByChanged}
                                row>
                                <FormControlLabel
                                    value="overallChartData"
                                    control={<CustomRadioButton />}
                                    label="Assessments Overall"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="byGaiChartData"
                                    control={<CustomRadioButton />}
                                    label="GAI Overall"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </React.Fragment>
            );
        } else if (
            this.state.errorLoadingChart !== undefined &&
            this.state.errorLoadingChart !== null
        ) {
            theChart = <div>{this.state.errorLoadingChart}</div>;
        } else {
            theChart = <WaitSpinner></WaitSpinner>;
        }

        return (
            <div className={styles.root} style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid container style={{ gap: "15px", padding: "0" }}>
                    <Grid item xs={12} md={6} style={{ maxWidth: "calc(50% - 7.5px)", padding: "0" }}>
                        <Paper className={styles.paper} style={{ height: "100%", padding: "0", margin: "0" }}>
                            {theChart}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ maxWidth: "calc(50% - 7.5px)", padding: "0" }}>
                        <Paper className={styles.paper} style={{ padding: "0", margin: "0" }}>
                            <StudentGrid students={this.props.students} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }

    _getData = () => {
        //Don't get data if it's there already
        if (this.state[this.state.chartFilter] !== null) return;

        API.get(`CourseInstance/CourseAssessment(CourseInstanceId=${this.props.courseInstanceId},Filter='${this.state.chartFilter}')`)
            .then((response) => {
                if (response.status === 200)
                    this.setState({
                        [this.state.chartFilter]: response.data,
                        errorLoadingChart: null,
                    });
                else {
                    this.setState({
                        errorLoadingChart: "Unable to retrieve student chart.",
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    errorLoadingChart: "Error: Unable to retrieve student chart.",
                });
            });
    };
}

export default CourseInstanceDetails;
